import { ChatPath } from 'chat/paths'
import { LazyRouteObject } from 'router/utils/types'

const ChatRoutes: LazyRouteObject[] = [
  {
    path: ChatPath.Chat,
    Component: () => import('chat/routes/chat'),
  },
]

export default ChatRoutes
