import { AppPath } from 'app/paths'
import ChatRoutes from 'chat/routes'
import LoginRoutes from 'login/routes'
import { LazyRouteObject } from 'router/utils/types'

const appRoutes: LazyRouteObject[] = [
  {
    path: AppPath.BasePath,
    Component: () => import('app/routes/~'),
    children: [...LoginRoutes, ...ChatRoutes],
  },
]

export default appRoutes
