import { LoginPath } from 'login/paths'
import { LazyRouteObject } from 'router/utils/types'

const LoginRoutes: LazyRouteObject[] = [
  {
    path: LoginPath.Login,
    Component: () => import('login/routes/login'),
  },
]

export default LoginRoutes
